import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  Home,
  Loading,
  NoMatch,
  Dashboard,
  Routes,
  Team,
  Auth,
  GlobalStyles,
  EventPage,
  api,
  THEME,
  AlertMessage,
  ErrorPage,
} from "./";
import ScrollToTop from "./ScrollToTop";
import TermsCondition from "../app/components/TermsAndConditions";
import PrivacyPolicy from "../app/components/PrivacyPolicy";
import SubscribePlans from "./pages/SubscribePlans/SubscribePlans";
import RefundAndCancellationPolicy from "../app/components/RefundAndCancellationPolicy";
import ContactUs from "../app/components/ContactUs";
import Certificate from "./components/Certificate";
import dispatchConfig from "./utils/dispatchConfig";
const header_footer = 98;
function MApp() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [height, setHeight] = useState(window.innerHeight - header_footer);
  useEffect(() => {
    async function fetch() {
      try {
        const res = await api.getStatus();
        if (res.data.status !== "ok") {
          history.push("/auth/setup");
        }
      } catch (err) {}
      try {
        const res = await api.getConfig();
        const config = res.data.config;
        document.title = config.event_name;
        if (res.status === 200) {
          dispatchConfig(config, dispatch);
          dispatch({
            type: THEME,
            data: {
              theme_name: config.theme_name,
            },
          });
        }
      } catch (err) {}
    }
    fetch();
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight - header_footer;
      setHeight(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [dispatch, history]);
  let [theme, config, loading, alertMessage] = useSelector((state) => [
    state.theme,
    state.config,
    state.loading,
    state.alertMessage,
  ]);
  var new_theme;
  if (config.theme_name === "Custom") {
    const { text_color, theme_light, theme_medium, theme_dark } = config;
    theme = {
      text_color,
      light: theme_light,
      medium: theme_medium,
      dark: theme_dark,
    };
  }
  if (config) new_theme = { ...theme, accent: config.accent_color };

  return (
    <ThemeProvider theme={new_theme}>
      <GlobalStyles />
      <ScrollToTop />
      {alertMessage.open && <AlertMessage />}
      {loading && <Loading height={height} />}
      <Switch>
        <Route
          exact
          path={Routes.default}
          render={(props) => <Home {...props} height={height} />}
        />
        {config.plan_mode && (
          <Route
            path={Routes.subscribePlan}
            render={(props) => <SubscribePlans {...props} height={height} />}
          />
        )}
        <Route
          path={Routes.event}
          render={(props) => <EventPage {...props} height={height} />}
        />
        <Route
          path={Routes.team}
          render={(props) => <Team {...props} height={height} />}
        />
        <Route
          path={Routes.dashboard}
          render={(props) => <Dashboard {...props} height={height} />}
        />
        <Route
          path={Routes.auth}
          render={(props) => <Auth {...props} height={height} />}
        />
        <Route
          path="/error"
          render={(props) => <ErrorPage {...props} height={height} />}
        />
        <Route path={Routes.termsAndCondition} component={TermsCondition} />
        <Route path={Routes.privacyPolicy} component={PrivacyPolicy} />
        <Route
          path={Routes.refundAndCancellationPolicy}
          component={RefundAndCancellationPolicy}
        />
        <Route path={Routes.certificate} component={Certificate} />
        <Route path={Routes.contactUs} component={ContactUs} />
        <Route path={Routes.notfound}>
          <NoMatch height={height} />
        </Route>
        <Route path="*">
          <NoMatch height={height} />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default MApp;
