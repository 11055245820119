import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RecaptchWrapper = ({ children, site_key }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={site_key}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchWrapper;
