import { useEffect, useState } from "react";
import Graph from "./Graph";
import { useSelector, useDispatch } from "react-redux";
import {
  GET_RANKS,
  SEARCH,
  api,
  headers,
  PaginationComponent,
  SmLoading,
  Toggle,
  Select,
} from "../../";

import { useHistory } from "react-router";
import branches from "../../../data/branches";

const TableBody = ({ paginateItems }) => {
  const history = useHistory();
  const config = useSelector((state) => state.config);

  var endpoint = "/dashboard/profile";
  if (config.team_mode) {
    endpoint = "/dashboard/teamstats";
  }

  return (
    <div className="table_data">
      {paginateItems.map((rank, index) => (
        <div key={index} className="table_data_item">
          <div className="flex-1">{rank.pos}</div>
          <div className="flex-4">
            <span
              className="pointer"
              onClick={() => history.push(`${endpoint}/${rank.team}`)}
            >
              {rank.team}
            </span>
          </div>
          <div className="flex-1">{rank.score}</div>
        </div>
      ))}
    </div>
  );
};

function ScoreBoard() {
  const dispatch = useDispatch();
  const [ranks, config, app] = useSelector((state) => [
    state.ranks,
    state.config,
    state.app,
  ]);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branch, setBranch] = useState("");
  const handleFilter = () => setFilter(!filter);
  const [filter, setFilter] = useState(false);
  useEffect(() => {
    async function fetch(loading) {
      if (loading) setLoading(true);
      try {
        let res;
        res = branch
          ? await api.getScoreboardByBranch(branch)
          : await api.getScoreboard();

        dispatch({
          type: GET_RANKS,
          data: res?.data?.standings || [],
        });

        res = branch
          ? await api.graphDataByBranch(branch)
          : await api.graphData();

        setGraphData(res?.data?.graph_data || []);
      } catch (error) {
      } finally {
        if (loading) setLoading(false);
      }
    }
    fetch(true);
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: `search ${config.team_mode ? "team" : "user"}`,
      },
    });
    var timeout = setInterval(async () => {
      fetch(false);
    }, 30000);
    return () => {
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
      clearInterval(timeout);
    };
  }, [dispatch, config.team_mode, branch]);
  if (config.team_mode) headers.ranks[1] = "Team";
  else headers.ranks[1] = "User";

  return (
    <div className="scoreboard">
      {filter && (
        <Toggle classes="users_branches" handleToggle={handleFilter}>
          <h2 className="mb-2">Apply Filter</h2>
          <div style={{ height: 400 }}>
            <Select
              toggle_id={"users_branches"}
              options={branches}
              placeholder="Select Branch"
              label="branch"
              onChange={(branch) => {
                setBranch(branch);
                setFilter(false);
              }}
              value={branch}
            />
          </div>
        </Toggle>
      )}

      {loading ? (
        <SmLoading />
      ) : (
        <>
          <Graph graphData={graphData} />
          <PaginationComponent
            heading="RANKS"
            headers={headers.ranks}
            items={ranks}
            pageSize={10}
            TableBody={TableBody}
            removeFilter={() => setBranch("")}
            filterValue={branch}
            handleFilter={app.isAdmin ? handleFilter : null}
          />
        </>
      )}
    </div>
  );
}

export default ScoreBoard;
