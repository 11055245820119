import React from "react";

function TableHeader({ headers }) {
  return (
    <div className="table_header">
      {headers.map((header, index) => (
        <div key={index} className="flex-1">
          <h3>{header}</h3>
        </div>
      ))}
    </div>
  );
}

export default TableHeader;
