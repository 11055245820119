import React, { useEffect, useState } from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfBytes from "../../data/pdfBytes";
import signatureBytes from "../../data/signatureBytes";

import { useParams } from "react-router-dom";
import Loading from "./Loading";
import { api } from "../services";
function Certificate({ certificateId }) {
  const params = useParams();
  const id = params?.id || certificateId;
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const url = `${window.location.origin}/api/sharecertificate/${id}`;

  useEffect(() => {
    const createCertificate = async (
      existingPdfBytes,
      existingImageBytes,
      name,
      challenge_name
    ) => {
      // Load a PDFDocument from the existing PDF bytes
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Embed the Helvetica font
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // Get the first page of the document
      const [firstPage] = pdfDoc.getPages();

      // Get the width and height of the first page
      const { width } = firstPage.getSize();

      const pageWidth = width; // Assuming standard US Letter size (8.5 x 11 inches)
      const textWidth = helveticaFont.widthOfTextAtSize(name, 35);

      const xCoordinate = (pageWidth - textWidth) / 2;

      // Draw a string of text diagonally across the first page
      firstPage.drawText(name, {
        x: xCoordinate,
        y: 295,
        size: 35,
        font: helveticaFont,
        color: rgb(148 / 255, 148 / 255, 148 / 255),
      });
      firstPage.drawText(challenge_name, {
        x: xCoordinate,
        y: 180,
        size: 35,
        font: helveticaFont,
        color: rgb(148 / 255, 148 / 255, 148 / 255),
      });

      const image = await pdfDoc.embedPng(existingImageBytes);

      firstPage.drawImage(image, {
        x: width - 220,
        y: 50,
        width: 200, // Width
        height: 100, // Height
      });

      // Serialize the PDFDocument to bytes (a Uint8Array)
      const modifiedPdfBytes = await pdfDoc.save();
      // Create Blob
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // setPdfUrl(url + "#toolbar=0&navpanes=0");
      setPdfUrl(url);
    };
    if (id) {
      setLoading(true);
      api
        .getCertificate(id)
        .then((res) => {
          const certificate = res.data?.certificate_data;
          if (certificate) {
            createCertificate(
              pdfBytes,
              signatureBytes,
              certificate.user,
              certificate.challenge_name
            );
          }
        })
        .catch((err) => setError(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className="certificate-container center">
        <h1>{error?.response?.data?.status || error?.message}</h1>;
      </div>
    );
  }

  if (!pdfUrl || !id) return null;
  return (
    <div className="certificate-container">
      <iframe title="certificate-mobile" src={url} className="certificate" />
    </div>
  );
}

export default Certificate;
