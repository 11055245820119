import React from "react";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  FacebookIcon,
} from "react-share";
import LinkIcon from "@material-ui/icons/Link";
import Certificate from "../../app/components/Certificate";
import { useDispatch } from "react-redux";
import { ALERT } from "../";

import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";

function ShareCertificate({ certificateId }) {
  const params = useParams();
  const id = params?.id || certificateId;
  const dispatch = useDispatch();
  const url = `${window.location.origin}/api/sharecertificate/${id}`;
  return (
    <div style={{ width: "100%" }}>
      <div className="share-certificate">
        <TwitterShareButton url={url}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <FacebookShareButton url={url}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>

        <Tooltip title="Copy Link" placement="bottom" arrow>
          <Button
            style={{
              width: 35,
              height: 55,
              padding: 0,
              margin: 0,
              minWidth: 40,
            }}
          >
            <div
              onClick={() => {
                navigator.clipboard.writeText(url);
                dispatch({
                  type: ALERT,
                  data: {
                    open: true,
                    message: "Copied",
                    type: "success",
                  },
                });
              }}
              className="icon icon__primary"
              style={{
                borderRadius: 20,
                width: 35,
                height: 35,
                background: "var(--green)",
              }}
            >
              <LinkIcon
                style={{
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                  transform: "rotate(90deg)",
                }}
              />
            </div>
          </Button>
        </Tooltip>

        <LinkedinShareButton url={url}>
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
      </div>

      <Certificate certificateId={id} />
    </div>
  );
}

export default ShareCertificate;
