import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";

import Login from "./Login";
import Register from "./Register";
// import default_event from "../../assets/default-event.png";
import { LOADING, api, Routes } from "../../";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import NavLogo from "../../components/NavLogo";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import logo from "./logo.png";
import dispatchConfig from "../../utils/dispatchConfig";

const RecaptchWrapper = ({ children, site_key }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={site_key}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

function Auth({ height }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [site_key, setSiteKey] = useState("");
  //   const [config, app] = useSelector((state) => [state.config, state.app]);
  useEffect(() => {
    async function fetch() {
      try {
        dispatch({
          type: LOADING,
        });
        try {
          var res = await api.getSiteKey();
          setSiteKey(res.data.site_key);
        } catch (err) {}
        try {
          res = await api.getStatus();
          if (res.data.status !== "ok") {
            history.push("/auth/setup");
          } else {
            sessionStorage.setItem("setup", "true");
          }
        } catch (err) {}
        res = await api.getConfig();
        dispatchConfig(res.data.config, dispatch);
        await api.getUserStatus();
        history.push(Routes.challenge);
      } catch (err) {
        dispatch({
          type: LOADING,
        });
      }
    }

    fetch();
  }, [dispatch, history]);
  if (site_key) {
    return (
      <RecaptchWrapper site_key={site_key}>
        {/* <div className="m_navbar m_auth_login"> */}
        {/* <NavLogo app={app} show={true} /> */}
        {/* </div> */}
        <div
          className="m_container m_flex-column m_pentest-auth-container"
          style={{ height: height + 98, background: "var(--dark)" }}
        >
          {/* <div className="m_text-center">
            <img
              className="m_login_logo"
              src={config.event_logo || default_event}
              alt="logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = default_event;
              }}
            />
          </div> */}
          <div className="m_auth__container m_mb-2 center">
            <div className="m_auth__inputs">
              <div className="center mt-1 mb-2">
                <img
                  src={logo}
                  style={{ width: "90%", objectFit: "contain" }}
                  alt="logo"
                />
              </div>
              <Switch>
                <Route path="/auth/login">
                  <Login site_key={site_key} />
                </Route>
                <Route path="/auth/forgotpassword">
                  <ForgotPassword site_key={site_key} />
                </Route>
                <Route path="/auth/resetpassword/:userid/:secret_token">
                  <ResetPassword site_key={site_key} />
                </Route>
                <Route path="/auth/register">
                  <Register endpoint="register" site_key={site_key} />
                </Route>
                <Route path="/auth/setup">
                  <Register endpoint="setup" />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </RecaptchWrapper>
    );
  }
  return (
    <>
      {/* <div className="m_navbar m_auth_login"> */}
      {/* <NavLogo app={app} show={true} /> */}
      {/* </div> */}
      <div
        className="m_container m_flex-column m_pentest-auth-container"
        style={{ height: height + 98, background: "var(--dark)" }}
      >
        {/* <div className="m_text-center">
          <img
            className="m_login_logo"
            src={config.event_logo || default_event}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = default_event;
            }}
          />
        </div> */}
        <div className="m_auth__container m_mb-2 center">
          <div className="m_auth__inputs">
            <div className="center mt-1 mb-2">
              <img
                src={logo}
                style={{ width: "90%", objectFit: "contain" }}
                alt="logo"
              />
            </div>
            <Switch>
              <Route path="/auth/login">
                <Login site_key={site_key} />
              </Route>
              <Route path="/auth/forgotpassword">
                <ForgotPassword site_key={site_key} />
              </Route>
              <Route path="/auth/resetpassword/:userid/:secret_token">
                <ResetPassword site_key={site_key} />
              </Route>
              <Route path="/auth/register">
                <Register endpoint="register" site_key={site_key} />
              </Route>
              <Route path="/auth/setup">
                <Register endpoint="setup" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
