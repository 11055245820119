import { GET_CONFIG } from "../store";
import country_codes from "./country_codes";
function dispatchConfig(config, dispatch) {
  const country = country_codes.find((c) => c.name === config.country);
  dispatch({
    type: GET_CONFIG,
    data: { ...config, countryCode: country?.code?.toLowerCase() },
  });
}
export default dispatchConfig;
