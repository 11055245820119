import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CONFIG,
  THEME,
  country_codes,
  api,
  Select,
  ColorPickInput,
  TableHeading,
  Checkbox,
  MarkdownEditor,
  InputFile,
  MediaLibrary,
  Toggle,
  MuiDateTimePicker,
  Input,
  ALERT,
  themes,
} from "../../";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import dispatchConfig from "../../utils/dispatchConfig";

function Event() {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const [event_name, setevent_name] = useState(config.event_name || "");
  const [event_about, setevent_about] = useState(config.event_about || "");
  const [event_logo, setevent_logo] = useState(config.event_logo || "");
  const [company_logo, setcompany_logo] = useState(config.company_logo || "");
  const [country, setcountry] = useState(config.country || "");
  const [text_color, settext_color] = useState(config.text_color || "");
  const [theme_dark, settheme_dark] = useState(config.theme_dark || "");
  const [theme_light, settheme_light] = useState(config.theme_light || "");
  const [theme_medium, settheme_medium] = useState(config.theme_medium || "");
  const [accent_color, setaccent_color] = useState(config.accent_color || "");
  const [start_time, setstart_time] = useState(config.start_time || "");
  const [end_time, setend_time] = useState(config.end_time || "");
  const [game_clock, setgame_clock] = useState(config.game_clock || false);
  const [plan_mode, setPlanMode] = useState(config.plan_mode || false);
  const [max_team_members, setmax_team_members] = useState(
    config.max_team_members || ""
  );
  const [verify_users, setverify_users] = useState(
    config.verify_users || false
  );
  const [scoreboard_enabled, setscoreboard_enabled] = useState(
    config.scoreboard_enabled || false
  );
  const [team_mode, setteam_mode] = useState(config.team_mode || false);
  const [theme_name, settheme_name] = useState(
    config.theme_name || "Ambient Black"
  );
  const [loading, setLoading] = useState(false);
  const [uploadFiles1, setUploadFile1] = useState("");
  const [uploadFiles2, setUploadFile2] = useState("");
  const [customTheme, setcustomTheme] = useState(false);
  const [loading1, setloading1] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [media_library, setMediaLibrary] = useState("");
  async function handleSubmit() {
    if (team_mode) {
      if (max_team_members === "") {
        dispatch({
          type: ALERT,
          data: {
            open: true,
            message: "Maximum Users is required",
            type: "error",
          },
        });
        return;
      }
    }
    var _text_color = text_color;
    if (theme_name !== "Custom") {
      _text_color = themes[theme_name].text_color;
    }
    var config = {
      event_name,
      event_logo,
      text_color: _text_color,
      accent_color,
      theme_dark,
      theme_light,
      theme_medium,
      start_time,
      end_time,
      event_about,
      scoreboard_enabled,
      theme_name,
      team_mode,
      company_logo,
      game_clock,
      plan_mode,
      verify_users,
      country,
    };
    if (team_mode) {
      config = {
        ...config,
        max_team_members: parseInt(max_team_members),
      };
    }
    try {
      setLoading(true);
      await api.updateConfig(config);
      const res = await api.getConfig();
      dispatchConfig(res.data.config, dispatch);
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Event updated",
          type: "success",
        },
      });
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: error.response && error.response.data.status,
          type: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    dispatch({
      type: THEME,
      data: { theme_name: config.theme_name },
    });
  }, [dispatch, config.theme_name]);
  function handleDateTimeChange(value, setFunc) {
    const date = new Date(value).getTime() / 1000;
    setFunc(date);
  }

  const handleChangeTheme = (themename) => {
    dispatch({
      type: THEME,
      data: { ...config, theme_name: themename },
    });
    dispatch({
      type: GET_CONFIG,
      data: { ...config, theme_name: themename },
    });
    settheme_name(themename);
  };
  async function uploadLogo1() {
    if (!uploadFiles1) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please choose file",
          type: "warning",
        },
      });
      return;
    }
    setloading1(true);
    try {
      const res = await api.uploadImage(uploadFiles1);
      setevent_logo(res.data.file_url);
      setUploadFile1("");
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File uploaded",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File can't be uploaded",
          type: "error",
        },
      });
    } finally {
      setloading1(false);
    }
  }
  async function uploadLogo2() {
    if (!uploadFiles2) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please choose file",
          type: "warning",
        },
      });
      return;
    }
    setloading2(true);
    try {
      const res = await api.uploadImage(uploadFiles2);
      setcompany_logo(res.data.file_url);
      setUploadFile2("");
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File uploaded",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File can't be uploaded",
          type: "error",
        },
      });
    } finally {
      setloading2(false);
    }
  }

  const themes_options = [
    { category_name: "Ambient Black", category_icon: "no-icon" },
    { category_name: "Charcoal Black", category_icon: "no-icon" },
    { category_name: "Chocolate Brown", category_icon: "no-icon" },
    { category_name: "Classic White", category_icon: "no-icon" },
    { category_name: "Dracula", category_icon: "no-icon" },
    { category_name: "Frozen Blue", category_icon: "no-icon" },
    { category_name: "Military Green", category_icon: "no-icon" },
    { category_name: "Pacific Blue", category_icon: "no-icon" },
    { category_name: "Silicone Green", category_icon: "no-icon" },
    { category_name: "Slate Grey", category_icon: "no-icon" },
    {
      category_name: "Custom",
      category_icon: "no-icon",
      handler: () => {
        setcustomTheme(!customTheme);
        handleChangeTheme("Custom");
      },
    },
  ];
  const countries = country_codes.map(({ name }) => {
    return {
      category_name: name,
      category_icon: "no-icon",
    };
  });

  return (
    <>
      {customTheme && (
        <Toggle
          classes="custom_color_inputs"
          handleToggle={() => setcustomTheme(!customTheme)}
        >
          <div className="d-flex">
            <ColorPickInput
              onChange={(color) => settext_color(color)}
              value={text_color}
              label="Text"
            />
            <ColorPickInput
              onChange={(color) => settheme_light(color)}
              value={theme_light}
              label="Light"
            />
          </div>
          <div className="d-flex">
            <ColorPickInput
              onChange={(color) => settheme_medium(color)}
              value={theme_medium}
              label="medium"
            />
            <ColorPickInput
              onChange={(color) => settheme_dark(color)}
              value={theme_dark}
              label="Dark"
            />
          </div>
        </Toggle>
      )}
      {media_library && (
        <Toggle
          classes="challengePanel"
          handleToggle={() => setMediaLibrary("")}
        >
          <MediaLibrary
            onInsert={(logo) =>
              setevent_about(
                `${event_about} ![Image](${encodeURI(logo.trim())})`
              )
            }
            type="event"
            handleToggle={() => setMediaLibrary("")}
          />
        </Toggle>
      )}
      <div className="table event">
        <TableHeading heading="EVENT" />
        <InputFile
          label__side="Event Logo"
          file={uploadFiles1}
          onChange={setUploadFile1}
          onClick={loading1 ? null : uploadLogo1}
          btn={`${loading1 ? "Uploading" : "Upload file"} `}
          id="event_event_logo"
          value={event_logo.split("-").pop()}
          loading={loading1}
          tag="(720 × 360)"
        />

        <InputFile
          label__side="Company Logo"
          file={uploadFiles2}
          onChange={setUploadFile2}
          onClick={loading2 ? null : uploadLogo2}
          btn={`${loading2 ? "Uploading" : "Upload file"} `}
          id="event_company_logo"
          loading={loading2}
          value={company_logo.split("-").pop()}
          tag="(360 × 80)"
        />
        <Select
          options={themes_options}
          placeholder="Enter Theme Colour"
          label__side="Theme Colour"
          onChange={handleChangeTheme}
          value={theme_name}
        />
        <div className="accent_color">
          <ColorPickInput
            onChange={(color) => setaccent_color(color)}
            value={accent_color}
            label__side="Accent Colour"
          />
        </div>
        <div className="d-flex">
          <label className="event_label_side">
            <h3>Time</h3>
          </label>
          <div className="event_responsive">
            <MuiDateTimePicker
              onChange={(e) => handleDateTimeChange(e, setstart_time)}
              value={start_time}
              id1="date-1"
              id2="time-1"
            />
            <label className="from mt-1 mb-1">
              <h3>To</h3>
            </label>
            <MuiDateTimePicker
              onChange={(e) => handleDateTimeChange(e, setend_time)}
              value={end_time}
              id1="date-2"
              id2="time-2"
            />
          </div>
        </div>
        <Select
          className="mt-1"
          options={countries}
          placeholder="Select Country"
          label__side="Country"
          onChange={setcountry}
          value={country}
        />
        <div className="d-flex">
          <label className="event_label_side">
            <h3>Scoreboard</h3>
          </label>
          <Checkbox
            label="Enabled"
            id="scoreboard_enabled"
            checked={scoreboard_enabled}
            onChange={() => setscoreboard_enabled(!scoreboard_enabled)}
          />
        </div>
        <div className="d-flex mt-1 b mb-1">
          <label className="event_label_side">
            <h3>Team Mode</h3>
          </label>
          <Checkbox
            label="On"
            id="team_mode"
            checked={team_mode}
            onChange={() => setteam_mode(!team_mode)}
          />
        </div>
        {team_mode && (
          <Input
            className="mt-1"
            label__side="Maximum Users"
            placeholder="Enter 0 for unlimited users"
            value={max_team_members}
            onChange={setmax_team_members}
            type="number"
          />
        )}
        <div className="d-flex">
          <label className="event_label_side">
            <h3>Verify Users</h3>
          </label>
          <Checkbox
            label="Enabled"
            id="verify_users"
            checked={verify_users}
            onChange={() => setverify_users(!verify_users)}
          />
        </div>
        <div className="d-flex mt-1">
          <label className="event_label_side">
            <h3>Game Clock</h3>
          </label>
          <Checkbox
            label="Enabled"
            id="game_clock"
            checked={game_clock}
            onChange={() => setgame_clock(!game_clock)}
          />
        </div>
        <div className="d-flex mt-1">
          <label className="event_label_side">
            <h3>Plan Mode</h3>
          </label>
          <Checkbox
            label="Enabled"
            id="plan_mode"
            checked={plan_mode}
            onChange={() => setPlanMode(!plan_mode)}
          />
        </div>
        <Input
          className="mt-1"
          label__side="event name"
          value={event_name}
          onChange={setevent_name}
        />
        <div className="event_editor mt-4">
          <label
            style={{
              position: "relative",
            }}
            className="d-flex"
          >
            <div
              style={{
                position: "absolute",
                right: "3rem",
                top: "-6rem",
              }}
            >
              <div
                onClick={() => setMediaLibrary("event_about")}
                className="icon media_libray_markdown"
              >
                <div className="icon icon__primary">
                  <AddPhotoAlternateOutlinedIcon />
                </div>
              </div>
            </div>
          </label>

          <MarkdownEditor
            media_library={media_library}
            value={event_about}
            onChange={setevent_about}
          />
        </div>

        <div className="d-flex justify-content-center mt-1">
          <button
            onClick={!loading ? handleSubmit : null}
            style={{ width: "11rem" }}
            className={`btn  ${loading ? "tab-btn" : "btn btn--medium"}`}
          >
            {loading ? "Updating" : "Update"}
          </button>
        </div>
      </div>
    </>
  );
}

export default Event;
