import React, { useState } from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  GET_USERS,
  LOADING,
  api,
  InputPassword,
  Input,
  Checkbox,
  ALERT,
  Select,
} from "../../";
import InputPhoneNumber from "../../components/InputPhoneNumber";
import branches from "../../../data/branches";

function UserEdit({ handleToggle, user }) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState(user.username || "");
  const [password, setPassword] = useState(user.password || "");
  const [team_name, setTeamName] = useState(user.team_name || "");
  const [email, setEmail] = useState(user.email || "");
  const [banned, setBanned] = useState(user.banned || false);
  const [hidden, setHidden] = useState(user.hidden || false);
  const [admin, setAdmin] = useState(user.admin || false);
  const [mobile, setMobile] = useState(user.mobile || "");
  const [verified, setVerified] = useState(user.verified || false);
  const [branch, setBranch] = useState(user.branch || "");
  const config = useSelector((state) => state.config);
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(null);
  const [isValidPhoneNumber, notValidPhoneNumber] = useState(false);

  useEffect(() => {
    async function fetchPlans() {
      try {
        let res = await api.getPlans();
        let data = res.data.plans;
        if (data?.length) {
          const _data = data.map(({ id, name }) => {
            if (user?.plan === id) {
              setPlan(name);
            }
            return {
              id,
              category_name: name,
              category_icon: "no-icon",
            };
          });
          setPlans(_data);
        }
      } catch (error) {}
    }
    fetchPlans();
  }, [user?.plan]);

  const handleSubmit = async () => {
    if (!isValidPhoneNumber) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please enter a valid number",
          type: "error",
        },
      });
      return;
    }

    const planFound = plans.find((_plan) => _plan.category_name === plan);
    const _plan = planFound ? planFound.id : "NA";
    const userData = {
      id: user.id,
      username,
      email,
      team_name,
      password,
      banned,
      admin,
      hidden,
      mobile,
      verified,
      branch,
      plan: _plan,
    };

    try {
      dispatch({
        type: LOADING,
        data: true,
      });
      await api.updateUser(userData);
      const res = await api.getUsers();
      dispatch({
        type: GET_USERS,
        data: res.data.users,
      });
      handleToggle();
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Changes applied",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
        data: true,
      });
    }
  };
  function handleUsername(username) {
    const len = username.length - 1;
    if (!username.match(/^[0-9a-zA-Z_ ]+$/) && username[len]) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `' ${username[len]} ' not allowed`,
          type: "error",
        },
      });
    } else {
      setUsername(username);
    }
  }
  function handleTeamName(teamname) {
    const len = teamname.length - 1;
    if (!teamname.match(/^[0-9a-zA-Z_ ]+$/) && teamname[len]) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `' ${teamname[len]} ' not allowed`,
          type: "error",
        },
      });
    } else {
      setTeamName(teamname);
    }
  }
  return (
    <>
      <Input
        label="USERNAME"
        placeholder="Enter Username"
        value={username}
        onChange={handleUsername}
      />
      <InputPhoneNumber
        value={mobile}
        onChange={setMobile}
        notValidPhoneNumber={notValidPhoneNumber}
      />
      <InputPassword
        label="reset password"
        placeholder="Enter Password"
        value={password}
        onChange={setPassword}
      />

      <Select
        options={branches}
        placeholder="Branch"
        label="branch"
        onChange={setBranch}
        value={branch}
      />

      {config.team_mode && (
        <Input
          label="team name"
          placeholder="Enter Team Name"
          value={team_name}
          onChange={handleTeamName}
        />
      )}
      <Input
        label="email"
        placeholder="Enter Email"
        value={email}
        onChange={setEmail}
      />

      <Select
        className="mt-1"
        label="Plan"
        options={plans}
        placeholder="Select Plan"
        onChange={setPlan}
        value={plan}
      />

      <div
        className="d-flex justify-content-center mb-1"
        style={{ marginTop: "10px", gap: "30px" }}
      >
        <Checkbox
          checked={admin}
          onChange={() => setAdmin(!admin)}
          label="admin"
          id="user_admin"
        />
        <Checkbox
          checked={hidden}
          onChange={() => setHidden(!hidden)}
          label="hidden"
          id="user_hidden"
        />
      </div>
      <div
        className="d-flex justify-content-center mb-1"
        style={{ marginTop: "10px", gap: "30px" }}
      >
        <Checkbox
          checked={banned}
          onChange={() => setBanned(!banned)}
          label="banned"
          id="user_banned"
        />
        <Checkbox
          checked={verified}
          onChange={() => setVerified(!verified)}
          label="verified"
          id="user_verified"
        />
      </div>
      <div className="text-center mt-2" style={{ marginTop: "30px" }}>
        <button onClick={handleSubmit} className="btn btn--medium">
          Submit
        </button>
      </div>
    </>
  );
}

export default UserEdit;
