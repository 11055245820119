import { useState } from "react";
import { Nav } from "../";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
function Select({
  className,
  onChange,
  children,
  label = null,
  label__side = null,
  value,
  options,
  placeholder,
  toggle_id,
}) {
  const [hide, setHide] = useState(false);
  const handleClick = (item) => {
    onChange(item.category_name);
    setHide(false);
  };
  return (
    <div className={`input-group-container ${className}`}>
      {(label || label === "") && (
        <label className="d-flex">
          <h6 className="spaced">{label.split("*")[0]}</h6>
          {label.slice(-1) === "*" && (
            <h6 style={{ color: "var(--red)" }}>{label.slice(-1)}</h6>
          )}
        </label>
      )}
      <div className="input-group-icon-container">
        {(label__side || label__side === "") && (
          <label className="event_label_side">
            <h3>{label__side}</h3>
          </label>
        )}
        <div onClick={() => setHide(!hide)} className="input-group-icon">
          <div className="input-group">
            <div className="input select-container">
              {value ? (
                <h5 className="white_space">{value}</h5>
              ) : (
                <div className="select-placeholder">
                  <h5>{placeholder}</h5>
                </div>
              )}
              <div className="icon">
                <KeyboardArrowDownIcon />
              </div>
              {hide && (
                <div className="options-container">
                  <div className="options">
                    {options.map((d, index) => (
                      <Nav
                        toggle_id={toggle_id}
                        key={index}
                        nav={d}
                        cat={true}
                        onClick={d.handler ? d.handler : () => handleClick(d)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}

export default Select;
