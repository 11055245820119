import React, { useEffect } from "react";
import { PhoneInput, usePhoneValidation } from "react-international-phone";
import { useSelector } from "react-redux";
function InputPhoneNumber({ onChange, value, notValidPhoneNumber }) {
  let [config] = useSelector((state) => [state.config]);
  const { isValid } = usePhoneValidation(value);

  useEffect(() => {
    if (notValidPhoneNumber) {
      notValidPhoneNumber(isValid);
    }
  }, [isValid, notValidPhoneNumber]);
  return (
    <div className="m_input-group-container mb-1">
      <label className="label">
        <h6 className="m_spaced">Phone number</h6>
      </label>
      <PhoneInput
        onChange={onChange}
        defaultCountry={config?.countryCode || "in"}
        placeholder="phone number"
        value={value}
      />
    </div>
  );
}

export default InputPhoneNumber;
