import React from "react";
import _ from "lodash";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount <= 1) return null;
  const pages = _.range(1, pagesCount + 1);
  const totalPages = pages.length;
  return (
    <div className="pagination-container">
      <div className="pagination">
        <div
          onClick={() => onPageChange(-1, totalPages)}
          className="icon icon_primary"
        >
          <ChevronLeftOutlinedIcon
            style={{
              marginLeft: "-2rem",
              cursor: currentPage === 1 ? "context-menu" : "pointer",
              opacity: currentPage === 1 ? 0.5 : 1,
            }}
          />
        </div>
        {pages.map((page) => (
          <div
            key={page}
            onClick={() => onPageChange(page, totalPages, "jump")}
            className={
              page === currentPage ? "icon__primary" : "icon__primary active"
            }
          >
            <h3>{page}</h3>
          </div>
        ))}
        <div
          onClick={() => onPageChange(1, totalPages)}
          className="icon icon_primary"
        >
          <ChevronRightOutlinedIcon
            style={{
              cursor: currentPage === totalPages ? "context-menu" : "pointer",
              opacity: currentPage === totalPages ? 0.5 : 1,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
