import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import {
  api,
  ChallengeInfo,
  TeamUsers,
  TeamProfile,
  UserGraph,
  ALERT,
  SmLoading,
  Toggle,
  Input,
} from "../../";
function TeamStatesPage() {
  const { teamname } = useParams();
  const [teamstat, setTeamStat] = useState({});
  const [team, setTeam] = useState("");
  const [rank, setRank] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const config = useSelector((state) => state.config);
  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        var res;
        try {
          await api.getAdminStatus();
          res = await api.getAdminTeamStat(teamname);
        } catch (err) {
          res = await api.getTeamStat(teamname);
        }
        setTeamStat(res.data);
        try {
          res = await api.getTeam();
          setTeam(res.data);
        } catch (error) {}

        if (config.scoreboard_enabled) {
          res = await api.getScoreboard();
          const ranks = res.data.standings;
          if (ranks) {
            const obj = ranks.find((rank) => rank.team === teamname);
            if (obj) {
              setRank(obj.pos);
            }
          }
        }
      } catch (err) {
        dispatch({
          type: ALERT,
          data: {
            open: true,
            message: "Team not found",
            type: "error",
          },
        });
        history.goBack();
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, [dispatch, history, teamname, config]);
  async function handleSubmit() {
    setLoading(true);
    try {
      await api.updateTeamName({ team_name: newTeamName });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Team name Changed",
          type: "success",
        },
      });
      setToggle(false);
      history.push(`/dashboard/teamstats/${newTeamName}`);
      setNewTeamName("");
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: error.response && error.response.data.status,
          type: "error",
        },
      });
    }
    setLoading(false);
  }
  return (
    <div className="settings">
      {loading || !teamstat ? (
        <SmLoading />
      ) : (
        <>
          {toggle && (
            <Toggle handleToggle={() => setToggle(false)}>
              <Input
                label="new team name"
                placeholder="New Team Name"
                value={newTeamName}
                onChange={setNewTeamName}
              />
              <div className="text-center">
                <button onClick={handleSubmit} className="btn btn--medium">
                  Submit
                </button>
              </div>
            </Toggle>
          )}
          <TeamProfile
            handleToggle={() => setToggle(true)}
            rank={rank}
            points={teamstat.points}
            challenges_solved={teamstat.solved_challenges}
            team_name={teamstat.team_name}
            team_members={teamstat.members ? teamstat.members.length : 0}
            show_rank={config.scoreboard_enabled}
            team={team}
            total_challenges={teamstat.total_challenges}
          />
          <UserGraph
            data={teamstat.graph_points}
            heading="Points"
            label={teamname}
          />
          <ChallengeInfo
            categorical_challenges={teamstat.categorical_challenges}
            categorical_solves={teamstat.categorical_solves}
            solves={teamstat.solves}
          />
          <TeamUsers members={teamstat.members} />
        </>
      )}
    </div>
  );
}

export default TeamStatesPage;
