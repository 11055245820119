import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  api,
  GET_SOLVES,
  getIcon,
  ToolTip,
  ALERT,
  date_time,
} from "../../";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import LinkIcon from "@material-ui/icons/Link";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import MDEditor from "@uiw/react-md-editor";
import orderBy from "lodash/orderBy";
import { useHistory } from "react-router-dom";

function ChallengeDetail({
  setToggle,
  handleMainToggle,
  challenge,
  solve,
  site_key,
}) {
  const [flag, setFlag] = useState("");
  const [tabs, setTabs] = useState("solves");
  const [btn, setBtn] = useState("Submit");
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  var solved = null;
  const [app, config] = useSelector((state) => [state.app, state.config]);
  if (solve) solved = solve.find((s) => s.user === app.profile.username);
  var headers = ["Username", "Team", "Time"];
  if (!config.team_mode) {
    headers = ["Username", "Time"];
  }
  const sorted_solve = orderBy(solve, ["solve_time"], ["asc"]);
  const submitFlag = async () => {
    try {
      setLoading(true);
      var data = {
        cid: challenge.id,
        flag,
      };
      if (site_key) {
        const g_recaptcha_response = await executeRecaptcha("submit_flag");
        data = { ...data, g_recaptcha_response };
      }
      let res = await api.submitFlag(data);
      if (res.data.status !== "ok") {
        alert(res.data.status);
        return;
      }
      res = await api.solves();
      dispatch({ type: GET_SOLVES, data: res.data.solves });
      setToggle("congratulation");
      setBtn("Completed");
    } catch (err) {
      setToggle(err.response.data.status);
    } finally {
      setLoading(false);
      handleMainToggle();
    }
  };

  var solves_btn = "Solves";
  if (solve && solve.length === 1) {
    solves_btn = `${solve.length} Solve`;
  } else if (solve && solve.length > 1) {
    solves_btn = `${solve.length} Solves`;
  }
  return (
    <div className="challenge_detail_page_container">
      <div className="tabs">
        <div
          onClick={() => setTabs("solves")}
          className={`btn tabs-btn   ${tabs === "challenge" ? "active" : ""}`}
        >
          Challenge
        </div>
        <div
          onClick={() => setTabs("challenge")}
          className={`btn  tabs-btn ${tabs === "solves" ? "active" : ""}`}
        >
          {solves_btn}
        </div>
      </div>
      <div className="challenge_detail_page">
        {tabs === "solves" && (
          <>
            <div
              className="mb-1"
              style={{
                height: "calc(100% - 11rem)",
                overflow: "auto",
              }}
            >
              <div className="text-center mb-1">
                <h1>{challenge.name}</h1>
              </div>
              <h2>
                <MDEditor.Markdown source={challenge.description} />
              </h2>
            </div>
            <div className="challenge_category_type">
              <button
                style={{
                  backgroundColor:
                    challenge.difficulty.toLowerCase() === "easy"
                      ? "var(--blue)"
                      : challenge.difficulty.toLowerCase() === "medium"
                      ? "var(--yellow)"
                      : "var(--red)",
                }}
                className="btn  mr-1"
              >
                {challenge.difficulty}
              </button>
              <div className="icon">
                <OfflineBoltOutlinedIcon
                  style={{
                    color: "var(--yellow)",
                    marginRight: "0.5rem",
                  }}
                />
              </div>

              <h3> {challenge.points} </h3>
              <div
                className="icon"
                style={{
                  marginRight: "0.5rem",
                  marginLeft: "2.5rem",
                }}
              >
                {getIcon(challenge.category.category_icon)}
              </div>
              <h3>{challenge.category.category_name} </h3>

              <div className="enpoint_challenges">
                {challenge.endpoint && (
                  <ToolTip
                    n={2}
                    toggle_id="challenge_page_"
                    item="Copy endpoint"
                    outside
                  >
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(challenge.endpoint);
                        dispatch({
                          type: ALERT,
                          data: {
                            open: true,
                            message: "Copied",
                            type: "success",
                          },
                        });
                      }}
                      className="icon icon__primary"
                    >
                      <LinkIcon
                        style={{
                          marginRight: "0.5rem",
                          marginLeft: "0.5rem",
                          transform: "rotate(90deg)",
                        }}
                      />
                    </div>
                  </ToolTip>
                )}
                {challenge.file && (
                  <ToolTip
                    n={5}
                    item="Download file"
                    toggle_id="challenge_page_"
                    outside
                  >
                    <div
                      onClick={() => window.open(challenge.file)}
                      className="icon icon__primary"
                    >
                      <InsertDriveFileOutlinedIcon
                        style={{
                          marginRight: "0.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </div>
                  </ToolTip>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-evenly">
              <Input placeholder="Flag Input" value={flag} onChange={setFlag} />
              <div className={`ml-2 ${solved ? "disable" : ""}`}>
                <button
                  style={{
                    background: btn === "Completed" ? "green" : "",
                    width: "12rem",
                  }}
                  onClick={!solved ? submitFlag : null}
                  className={`btn  ${
                    loading || solved ? "tab-btn" : "btn btn--medium"
                  }`}
                >
                  {solved
                    ? "Completed"
                    : `${loading ? "Submitting" : "Submit"} `}
                </button>
              </div>
            </div>
          </>
        )}
        {tabs === "challenge" && (
          <>
            <div className="table_header">
              {headers.map((header, index) => (
                <div key={index} className="flex-1">
                  <h3>{header}</h3>
                </div>
              ))}
            </div>
            <div className="table_data">
              {sorted_solve &&
                sorted_solve.map((solve, index) => (
                  <div key={index} className="table_data_item">
                    <div className="flex-1">
                      <span
                        onClick={() =>
                          history.push(`/dashboard/profile/${solve.user}`)
                        }
                        className="pointer"
                      >
                        {solve.user}
                      </span>
                    </div>
                    {config.team_mode && (
                      <div className="flex-1">
                        <span
                          onClick={() =>
                            history.push(
                              `/dashboard/teamstats/${solve.teamname}`
                            )
                          }
                          className="pointer"
                        >
                          {solve.teamname}
                        </span>
                      </div>
                    )}
                    <div className="flex-1">{date_time(solve.solve_time)}</div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ChallengeDetail;
