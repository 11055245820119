import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { useHistory } from "react-router";
import {
  Toggle,
  UserEdit,
  api,
  headers,
  GET_USERS,
  SEARCH,
  PaginationComponent,
  LOADING,
  ALERT,
  SmLoading,
  Select,
} from "../../";

import orderBy from "lodash/orderBy";
import AddUser from "./AddUser";
import branches from "../../../data/branches";

const TableBody = ({ paginateItems }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const [user, setUser] = useState({});
  const handleToggle = () => setToggle(!toggle);
  const config = useSelector((state) => state.config);
  const [id, setID] = useState("");
  async function handleEdit(username) {
    dispatch({
      type: LOADING,
    });
    try {
      const res = await api.getUser(username);
      setUser(res.data);
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
      handleToggle();
    }
  }
  async function handleDelete(username) {
    dispatch({
      type: LOADING,
    });
    try {
      await api.deleteUser(username);
      try {
        const res = await api.getUsers();
        dispatch({
          type: GET_USERS,
          data: res.data.users,
        });
        dispatch({
          type: ALERT,
          data: {
            open: true,
            message: `'${username}' deleted successfully`,
            type: "success",
          },
        });
      } catch (error) {}
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }

  const filtered = orderBy(
    paginateItems,
    [(s) => s.username.toLowerCase()],
    ["asc"]
  );
  return (
    <>
      {toggle && (
        <Toggle handleToggle={handleToggle} classes="users_branches">
          <UserEdit handleToggle={handleToggle} user={user} />
        </Toggle>
      )}
      <div className="table_data">
        {filtered &&
          filtered.map((user, index) => (
            <div
              key={index}
              onClick={() => setID(user.id)}
              className={`table_data_item ${
                id === user.id ? "white-space" : ""
              }`}
            >
              <div className="flex-1">
                <span
                  onClick={() =>
                    history.push(`/dashboard/profile/${user.username}`)
                  }
                  className="pointer"
                >
                  {user.username}
                </span>
              </div>

              {config.team_mode && (
                <div className="flex-1">
                  <span
                    onClick={() =>
                      history.push(`/dashboard/teamstats/${user.team}`)
                    }
                    className="pointer"
                  >
                    {user.team}
                  </span>
                </div>
              )}
              <div className="d-flex justify-content-center flex-1">
                <div
                  onClick={() => handleEdit(user.username)}
                  className="icon__primary"
                >
                  <CreateOutlinedIcon />
                </div>
                <div
                  onClick={() => handleDelete(user.username)}
                  className="icon__primary"
                >
                  <DeleteOutlinedIcon />
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

function Users() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [toggle, setToggle] = useState(false);
  const config = useSelector((state) => state.config);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [branch, setBranch] = useState("");
  const handleToggle = () => setToggle(!toggle);
  const handleFilter = () => setFilter(!filter);
  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        let res;
        if (branch) {
          res = await api.getUsersByBranch(branch);
        } else {
          res = await api.getUsers();
        }
        dispatch({
          type: GET_USERS,
          data: res?.data?.users || [],
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetch();
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "search user",
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
  }, [dispatch, branch]);

  var header = headers.users;
  var filtered_users = users;
  if (!config.team_mode) {
    header = header.filter((h) => h !== "Team");
    filtered_users = users.map((user) => {
      return {
        id: user.id,
        username: user.username,
      };
    });
  }

  return (
    <div className="users_container">
      {toggle && (
        <Toggle classes="users_branches" handleToggle={handleToggle}>
          <AddUser toggle_id={"challengePanel"} handleToggle={handleToggle} />
        </Toggle>
      )}
      {filter && (
        <Toggle classes="users_branches" handleToggle={handleFilter}>
          <h2 className="mb-2">Apply Filter</h2>
          <div style={{ height: 400 }}>
            <Select
              toggle_id={"users_branches"}
              options={branches}
              placeholder="Filter by branch"
              label="branch"
              onChange={(branch) => {
                setBranch(branch);
                setFilter(false);
              }}
              value={branch}
            />
          </div>
        </Toggle>
      )}
      {loading ? (
        <SmLoading />
      ) : (
        <PaginationComponent
          heading="USERS"
          headers={header}
          items={filtered_users}
          pageSize={18}
          TableBody={TableBody}
          handleFilter={handleFilter}
          handleToggle={handleToggle}
          removeFilter={() => setBranch("")}
          filterValue={branch}
        />
      )}
    </div>
  );
}

export default Users;
