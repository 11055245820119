import http from "./httpService";

function forgetpassword(...rest) {
  return http.post("/forgotpassword", ...rest);
}
function resetpassword(...rest) {
  return http.post("/reset", ...rest);
}
function login(...rest) {
  return http.post("/login", ...rest);
}
function register(...rest) {
  return http.post("/register", ...rest);
}
function getScoreboard() {
  return http.get("/scoreboard");
}

function getScoreboardByBranch(branch) {
  return http.get(`/admin/scoreboard/branch/${branch}`);
}

function setup(...rest) {
  return http.post("/setup", ...rest);
}
function getStatus() {
  return http.get("/status");
}

function getConfig() {
  return http.get("/config");
}
function getUserState(username) {
  return http.get(`/user/userstats/${username}`);
}
function getAdminUserState(username) {
  return http.get(`/admin/userstats/${username}`);
}

function logout() {
  return http.get("/logout");
}

function graphData() {
  return http.get("/graphdata");
}
function graphDataByBranch(branch) {
  return http.get(`/admin/graphdata/branch/${branch}`);
}
function getAnnouncement() {
  return http.get("/user/announcements");
}
function addAnnouncements(...rest) {
  return http.post("/admin/addannouncement", ...rest);
}

function addChallenge(...rest) {
  return http.post("/admin/addchallenge", ...rest);
}

function updateChallenge(...rest) {
  return http.post("/admin/updatechallenge", ...rest);
}

function addUser(...rest) {
  return http.post("/admin/adduser", ...rest);
}

function updateUser(...rest) {
  return http.post("/admin/updateuser", ...rest);
}

function getUsers() {
  return http.get("/admin/users");
}
function getUsersByBranch(branch) {
  return http.get(`/admin/users/branch/${branch}`);
}
function getUser(username) {
  return http.get(`/admin/user/${username}`);
}

function getTeams() {
  return http.get("/admin/teams");
}

function getAdminTeam(teamname) {
  return http.get(`/admin/team/${teamname}`);
}
function getAdminStatus() {
  return http.get("/admin/status");
}

function getAdminChallenges() {
  return http.get("/admin/challenges");
}
function getAdminChallenge(id) {
  return http.get(`/admin/challenge/${id}`);
}
function getChallengeLogs() {
  return http.get("/admin/challengelogs");
}
function getUserLogs() {
  return http.get("/admin/userlogs");
}
function getAdminLogs() {
  return http.get("/admin/adminlogs");
}

async function uploadFile(file) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const res = await http.post("/admin/uploadchallengefile", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (err) {}
}

async function uploadImage(file) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const res = await http.post("/admin/uploadimage ", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (e) {}
}
async function getSiteKey(file) {
  return http.get("/recaptcha");
}
async function uploadMedia(file, type) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const res = await http.post(`/admin/uploadmedia/${type}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (e) {}
}
async function getMedia(type) {
  return http.get(`/admin/mediafiles/${type}`);
}
async function deleteMedia(type, filename) {
  return http.delete(`/admin/mediafile/${type}/${filename}`);
}

function deleteUser(username) {
  return http.delete(`/admin/user/${username}`);
}

function deleteChallenge(id) {
  return http.delete(`/admin/challenge/${id}`);
}

function deleteAnnouncement(id) {
  return http.delete(`/admin/announcement/${id}`);
}

function deleteTeam(teamname) {
  return http.delete(`/admin/team/${teamname}`);
}

function updateConfig(config) {
  return http.post("/admin/updateconfig", config);
}

function updateTeam(...rest) {
  return http.post("/admin/updateteam", ...rest);
}

function addCategory(...rest) {
  return http.post("/admin/addcategory", ...rest);
}
function getAdmin() {
  return sessionStorage.getItem("admin");
}

function getChallengesDeployments() {
  return http.get("/admin/challengedeployments");
}
function startChallenge(id) {
  return http.post(`/admin/startchallenge/${id}`);
}
function stopChallenge(id) {
  return http.post(`/admin/stopchallenge/${id}`);
}
function deleteChallengeDeployment(id) {
  return http.delete(`/admin/challengedeployment/${id}`);
}
function addChallengeDeployment(...rest) {
  return http.post("/admin/addchallengedeployment", ...rest);
}
function getImageFiles() {
  return http.get("/admin/imagefiles");
}
function getChallengeFiles() {
  return http.get("/admin/challengefiles");
}
function deleteChallengeFile(filename) {
  return http.delete(`/admin/challengefile/${filename}`);
}
function deleteImageFile(filename) {
  return http.delete(`/admin/imagefile/${filename}`);
}

function createTeam(...rest) {
  return http.post("/user/createteam", ...rest);
}

function joinTeam(...rest) {
  return http.post("/user/jointeam", ...rest);
}

function getUserStatus() {
  return http.get("/user/status");
}

function getChallenges() {
  return http.get("/user/challenges");
}
function getChallenge(id) {
  return http.get(`/user/challenge/${id}`);
}

function updateUsername(...rest) {
  return http.post("/user/updateusername", ...rest);
}

function updatePassword(...rest) {
  return http.post("/user/updatepassword", ...rest);
}

function getCurrentUser() {
  return sessionStorage.getItem("login");
}
function getUserTeam() {
  return sessionStorage.getItem("team");
}

function getTeamMembers() {
  return http.get("/user/getteammembers");
}

function submitFlag(...rest) {
  return http.post("/user/submitflag", ...rest);
}

function getTeamStat(team_name) {
  return http.get(`/user/teamstats/${team_name}`);
}
function getAdminTeamStat(team_name) {
  return http.get(`/admin/teamstats/${team_name}`);
}
function getTeam() {
  return http.get("/user/getteam");
}
function getCategories() {
  return http.get("/user/categories");
}
function deleteCategory(id) {
  return http.delete(`/admin/category/${id}`);
}
function updateCategory(...rest) {
  return http.post("/admin/updatecategory", ...rest);
}

function solve(id) {
  return http.get(`/user/solves/${id}`);
}

function solves() {
  return http.get("/user/usersolves");
}

function getNotifications() {
  return http.get("/user/notifications");
}

function exportLogs(type) {
  return http.get(`/admin/export/${type}`);
}

function exportLogsByBranch(branch) {
  return http.get(`/admin/export/users/branch/${branch}`);
}

function deleteAccount() {
  return http.delete("/user/deleteaccount");
}
function leaveTeam() {
  return http.delete("/user/leaveteam");
}
function updateTeamName(...rest) {
  return http.post("/user/updateteamname", ...rest);
}

function getPlans(...rest) {
  return http.get("/admin/plans");
}

function addPlan(...rest) {
  return http.post("/admin/addplan", ...rest);
}

function deletePlan(planId) {
  return http.delete(`/admin/plan/${planId}`);
}
function updatePlan(...rest) {
  return http.post(`/admin/plan/update`, ...rest);
}
function getPlanChallenges(planId) {
  return http.get(`/admin/planchallenge/${planId}`);
}
function addChallengeInPlan(...rest) {
  return http.post(`/admin/addplanchallenge`, ...rest);
}

function deletePlanChallenges(planId) {
  return http.delete(`/admin/planchallenge/${planId}`);
}

function getUserPlans() {
  return http.get(`/user/plans`);
}
function getPlansWithoutUser() {
  return http.get(`/plans`);
}
function subscribePlan(...rest) {
  return http.post(`/user/subscribeplan`, ...rest);
}
function getSubscriptions() {
  return http.get("/admin/subscriptions");
}
function getCertificate(id) {
  return http.get(`/certificate/${id}`);
}

const api = {
  exportLogsByBranch,
  graphDataByBranch,
  getScoreboardByBranch,
  getUsersByBranch,
  getCertificate,
  getPlansWithoutUser,
  getSubscriptions,
  addUser,
  updateChallenge,
  getPlans,
  subscribePlan,
  getUserPlans,
  deletePlanChallenges,
  addChallengeInPlan,
  getPlanChallenges,
  updatePlan,
  deletePlan,
  addPlan,
  updateTeamName,
  leaveTeam,
  deleteAccount,
  exportLogs,
  deleteCategory,
  updateCategory,
  getAnnouncement,
  solve,
  getAdminTeamStat,
  getAdminUserState,
  getUserTeam,
  solves,
  getCategories,
  createTeam,
  joinTeam,
  getSiteKey,
  updateUsername,
  updatePassword,
  getCurrentUser,
  getChallenges,
  getChallenge,
  getTeamMembers,
  getUserState,
  submitFlag,
  getTeamStat,
  getAdminTeam,
  getStatus,
  getNotifications,
  deleteMedia,
  graphData,
  logout,
  getMedia,
  uploadMedia,
  login,
  register,
  getConfig,
  getScoreboard,
  setup,
  getAdminLogs,
  getAdminStatus,
  deleteImageFile,
  resetpassword,
  forgetpassword,
  deleteChallengeFile,
  getChallengeFiles,
  getImageFiles,
  addChallengeDeployment,
  deleteChallengeDeployment,
  stopChallenge,
  startChallenge,
  getChallengesDeployments,
  getAdmin,
  deleteAnnouncement,
  getAdminChallenges,
  addCategory,
  uploadImage,
  updateTeam,
  getTeam,
  updateConfig,
  addAnnouncements,
  addChallenge,
  getUsers,
  getTeams,
  getUserStatus,
  getAdminChallenge,
  getUser,
  updateUser,
  getChallengeLogs,
  getUserLogs,
  uploadFile,
  deleteTeam,
  deleteUser,
  deleteChallenge,
};
export default api;
