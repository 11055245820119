import { useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SelectedChallenges,
  Routes,
  NOTIFICATIONS,
  LOADING,
  SETTING,
  SideBar,
  Navbar,
  api,
  build_categories,
  Challenges,
  gravatar,
  GET_CONFIG,
  THEME,
  SIDEBAR_CATEGORIES,
  Announcements,
  ScoreBoard,
  Logs,
  Event,
  Users,
  Statistics,
  Teams,
  AdminAnnouncements,
  AdminChallenges,
  GET_USER_CHALLENGES,
  Settings,
  TeamStatesPage,
  GET_SOLVES,
  Categories,
  GET_CATEGORIES,
  DashboardItems,
} from "../../";
import SubscribePlans from "../SubscribePlans/SubscribePlans";
import Plans from "../Plans/Plans";
import ShareCertificate from "../../components/ShareCertificate";
function Dashboard({ height }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [app, config, sidebarCategories] = useSelector((state) => [
    state.app,
    state.config,
    state.sidebarCategories,
  ]);

  const [site_key, setSiteKey] = useState("");
  useEffect(() => {
    async function fetch() {
      dispatch({
        type: LOADING,
      });
      let res = null;
      var config = null;
      try {
        res = await api.getSiteKey();
        setSiteKey(res.data.site_key);
      } catch (err) {}
      const newApp = {};
      try {
        res = await api.getConfig();
        config = res.data.config;
        if (res.status === 200) {
          dispatch({
            type: GET_CONFIG,
            data: config,
          });
          dispatch({
            type: THEME,
            data: {
              theme_name: config.theme_name,
            },
          });
        }
      } catch (err) {}
      try {
        res = await api.getUserStatus();
        if (res.status === 200) {
          newApp.profile = res.data;
          newApp.profile.avatar = gravatar(res.data.email);
          if (res.data.team.trim()) {
          } else {
            if (config.team_mode) {
              history.push(Routes.team);
            }
          }
        }
        try {
          res = await api.getAdminStatus();
          if (res.status === 200) {
            newApp.isAdmin = true;
            sessionStorage.setItem("admin", "true");
          }
        } catch (err) {}
        if (!sessionStorage.getItem("setup")) {
          try {
            const res = await api.getStatus();
            if (res.data.status !== "ok") {
              history.push("/auth/setup");
            } else {
              sessionStorage.setItem("setup", "true");
            }
          } catch (err) {}
        }

        res = await api.getChallenges();
        const challenges = res.data.challenges;
        if (challenges)
          dispatch({
            type: GET_USER_CHALLENGES,
            data: challenges,
          });
        res = await api.getCategories();
        const categories = res.data.categories;
        const sidebar_categories = build_categories(challenges, categories);
        res = await api.solves();
        dispatch({ type: GET_SOLVES, data: res.data.solves });
        if (sidebar_categories) {
          dispatch({
            type: SIDEBAR_CATEGORIES,
            data: sidebar_categories,
          });
        }
        dispatch({
          type: SETTING,
          data: newApp,
        });
        res = await api.getCategories();
        dispatch({
          type: GET_CATEGORIES,
          data: res.data.categories,
        });
      } catch (err) {
        history.push("/");
      } finally {
        dispatch({
          type: LOADING,
        });
      }
    }
    fetch();
    var timeout = setInterval(async () => {
      try {
        const res = await api.getNotifications();
        if (res.data.notifications) {
          dispatch({
            type: NOTIFICATIONS,
            data: res.data.notifications,
          });
        } else {
          dispatch({
            type: NOTIFICATIONS,
            data: [],
          });
        }
      } catch (error) {}
    }, 60000);

    return () => {
      clearInterval(timeout);
    };
  }, [dispatch, history]);

  return (
    <>
      <Navbar />
      <div className="m_container" style={{ height }}>
        <Route exact path={Routes.challenge} component={Challenges} />
        <Route
          path={Routes.challenge_category}
          render={(props) => (
            <SelectedChallenges {...props} site_key={site_key} />
          )}
        />
        <Route path="/dashboard/certificate/:id">
          <ShareCertificate />
        </Route>
        <Route path="/dashboard/categories">
          <Categories items={sidebarCategories} />
        </Route>

        {config.plan_mode && (
          <Route
            path={Routes.plans}
            render={(props) => <Plans {...props} site_key={site_key} />}
          />
        )}

        {!app.isAdmin && (
          <Route path={Routes.announcements} component={Announcements} />
        )}

        {!app.isAdmin && (
          <Route path={Routes.subscribePlan} component={SubscribePlans} />
        )}

        {config.scoreboard_enabled && (
          <Route path={Routes.scoreboard} component={ScoreBoard} />
        )}
        <Route path={Routes.teamStates} component={TeamStatesPage} />
        <Route path={Routes.profile} component={Settings} />
        {app.isAdmin && (
          <>
            <Route path={Routes.adminUsers} component={Users} />
            {config.team_mode && (
              <Route path={Routes.adminTeams} component={Teams} />
            )}
            <Route path={Routes.adminChallenges} component={AdminChallenges} />
            <Route path={Routes.announcements} component={AdminAnnouncements} />

            <Route path={Routes.adminStatistics} component={Statistics} />

            <Route path={Routes.adminLogs} component={Logs} />
            <Route path={Routes.adminEvent} component={Event} />
          </>
        )}
        <Route exact path="/dashboard" component={DashboardItems} />
      </div>

      <SideBar />
    </>
  );
}

export default Dashboard;
