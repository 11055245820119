import { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import hard from "../../assets/hard.png";
import medium from "../../assets/medium.png";
import easy from "../../assets/easy.png";
import ChallengeDetail from "./ChallengeDetail";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  api,
  searchData,
  SEARCH,
  SELECTED_CATEGORY,
  SmLoading,
  GET_USER_CHALLENGES,
  build_categories,
  GET_SOLVES,
  SIDEBAR_CATEGORIES,
} from "../../";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import orderBy from "lodash/orderBy";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { LockOutlined } from "@material-ui/icons";
import Locked from "./Locked";
import CardMembership from "@material-ui/icons/CardMembership";
function SelectedChallenges({ site_key }) {
  const dispatch = useDispatch();
  var { category } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [challenge, setChallenge] = useState("");
  const [solve, setSolve] = useState("");
  const [loading, setLoading] = useState(true);
  const [ID, setID] = useState("");
  const [locked, setLocked] = useState(false);
  const [app, user_challenges, solves, search, config] = useSelector(
    (state) => [
      state.app,
      state.user_challenges,
      state.solves,
      state.search,
      state.config,
    ]
  );
  useEffect(() => {
    async function fetch() {
      try {
        var res = await api.getChallenges();
        const challenges = res.data.challenges;
        if (challenges)
          dispatch({
            type: GET_USER_CHALLENGES,
            data: challenges,
          });
        res = await api.getCategories();
        const categories = res.data.categories;
        const sidebar_categories = build_categories(challenges, categories);
        res = await api.solves();
        dispatch({ type: GET_SOLVES, data: res.data.solves });
        if (sidebar_categories) {
          dispatch({
            type: SIDEBAR_CATEGORIES,
            data: sidebar_categories,
          });
          const obj = sidebar_categories.find(
            (s) => s.category_name.trim() === category.trim()
          );
          if (!obj) {
            history.push("/not-found");
          }
        }
      } catch (err) {}
    }
    fetch();
    dispatch({
      type: SELECTED_CATEGORY,
      data: category,
    });
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: category,
      },
    });

    return () => {
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
    };
  }, [dispatch, category, history]);

  const filteredChallenges = user_challenges.filter(
    (c) => c.category.trim() === category.trim()
  );
  var searchChallenges = searchData(search.value, filteredChallenges);

  var filtered = orderBy(
    searchChallenges,
    [(s) => s.name.toLowerCase()],
    ["asc"]
  );

  const handleChallenge = async (id, name = null) => {
    setLoading(true);
    setID(id);
    try {
      let res = await api.solve(id);
      const solves = res.data.all_solves;
      if (res.status === 200) {
        setSolve(solves);
      }
      res = await api.getChallenge(id);
      setChallenge(res.data);
      if (!name) history.push(`${pathname}/${id}`);
      else history.push(`/dashboard/challenge/${name}/${id}`);
    } catch (err) {
      history.push("/notfound");
    } finally {
      setLoading(false);
      setID("");
    }
  };

  function isContainerChallengeId(id) {
    return solves.find((s) => s.cid === id);
  }
  function isContainerUser(id) {
    if (app && app.profile)
      return solves.find(
        (s) => s.cid === id && s.user === app.profile.username
      );
    return false;
  }
  function getDefault(difficulty) {
    if (difficulty.toLowerCase() === "hard") {
      return hard;
    } else if (difficulty.toLowerCase() === "medium") {
      return medium;
    } else {
      return easy;
    }
  }

  function handleLockedChallenge(c) {
    if (c.locked) {
      return setLocked(true);
    }
    handleChallenge(c.id);
  }

  function handleLockedToggle() {
    setLocked(!locked);
  }

  return (
    <Switch>
      {locked && <Locked handleToggle={handleLockedToggle} />}
      <Route path="/dashboard/challenge/:name/:id">
        <GoogleReCaptchaProvider reCaptchaKey={site_key}>
          <ChallengeDetail
            site_key={site_key}
            challenge={challenge}
            solve={solve}
            handleChallenge={handleChallenge}
          />
        </GoogleReCaptchaProvider>
      </Route>
      <Route path="/dashboard/challenge">
        <div className="m_challenge_page">
          {filtered.length === 0 ? (
            <div className="m_text-center">
              <h5>
                <strong>No Challenge</strong>
              </h5>
            </div>
          ) : (
            filtered &&
            filtered.map((c) => (
              <div
                key={c.id}
                onClick={() => handleLockedChallenge(c)}
                className="m_card__container"
              >
                <div className="m_card__primary">
                  <div className="m_d-flex">
                    <div className="m_card_img">
                      <img
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = getDefault(c.difficulty);
                        }}
                        className="m_challenge__img m_mr-1"
                        src={c.challenge_logo || getDefault(c.difficulty)}
                        alt=""
                      />
                    </div>
                    <div className="m_flex-1 m_d-flex flex-column">
                      <div
                        className="m_card__heading"
                        style={{
                          width: "50vw",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {c.name}
                      </div>
                      <div
                        className="m_card_p m_card_p_lg"
                        style={{
                          flex: 1,
                          display: "flex",
                          // justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <button
                          style={{
                            backgroundColor:
                              c.difficulty.toLowerCase() === "easy"
                                ? "var(--blue)"
                                : c.difficulty.toLowerCase() === "medium"
                                ? "var(--yellow)"
                                : "var(--red)",
                          }}
                          className="m_btn  m_mr-1"
                        >
                          {c.difficulty}
                        </button>
                        <div className="m_d-flex align-items-center">
                          <div className="m_icon">
                            <OfflineBoltOutlinedIcon
                              style={{
                                color: "var(--yellow)",
                                marginRight: "0.5rem",
                              }}
                            />
                          </div>
                          {c.points}
                        </div>
                      </div>
                      {loading && c.id === ID && (
                        <div className="m_challenge_sm_loading">
                          <SmLoading />
                        </div>
                      )}
                      {c.locked && config.plan_mode && (
                        <div className="locked_challenge">
                          <LockOutlined />
                        </div>
                      )}
                      {solves && isContainerChallengeId(c.id) && (
                        <div className="m_tick m_icon">
                          <CardMembership
                            onClick={(e) => {
                              history.push(
                                `/dashboard/certificate/${
                                  isContainerUser(c.id).id
                                }`
                              );
                              e.stopPropagation();
                            }}
                            style={{ opacity: 0.5, marginRight: 5 }}
                          />
                          <CheckCircleOutlineIcon
                            style={{
                              color: `var(--${
                                isContainerUser(c.id) ? "green" : "text_color_5"
                              })`,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </Route>
    </Switch>
  );
}

export default SelectedChallenges;
