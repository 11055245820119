const branches = [
  {
    category_name: "RedTeam Dubai Campus",
    category_icon: "no-icon",
  },
  {
    category_name: "RedTeam Calicut Campus",
    category_icon: "no-icon",
  },
  {
    category_name: "RedTeam Kochi Campus",
    category_icon: "no-icon",
  },
  {
    category_name: "RedTeam Trivandrum Campus",
    category_icon: "no-icon",
  },
  {
    category_name: "RedTeam Thrissur Campus",
    category_icon: "no-icon",
  },
  {
    category_name: "RedTeam Perinthalmanna Campus",
    category_icon: "no-icon",
  },
  {
    category_name: "RedTeam Kottakkal Campus",
    category_icon: "no-icon",
  },
  {
    category_name: "RedTeam Chennai Campus",
    category_icon: "no-icon",
  },
  {
    category_name: "Others",
    category_icon: "no-icon",
  },
];

export default branches;
