import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { api, LOADING, Routes } from "../";
import default_event from "../assets/default-event.png";
import MDEditor from "@uiw/react-md-editor";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
// import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "react-router-dom";
import dispatchConfig from "../utils/dispatchConfig";

// import DiscordIcon from "./DiscordIcon";

function EventPage({ height }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);
  const config = useSelector((state) => state.config);
  useEffect(() => {
    async function fetch() {
      try {
        dispatch({
          type: LOADING,
        });
        try {
          await api.getUserStatus();
          setLogin(true);
        } catch (err) {
          setLogin(false);
        }
        const res = await api.getConfig();
        dispatchConfig(res.data.config, dispatch);
      } catch (err) {
      } finally {
        dispatch({
          type: LOADING,
        });
      }
    }
    fetch();
  }, [dispatch]);

  return (
    <>
      <div className="m_event_homepage">
        <div className="m_navbar">
          <div
            className="m_login_register"
            style={{
              justifyContent: login ? "flex-end" : "space-between",
            }}
          >
            {!login ? (
              <>
                <h4 onClick={() => history.push(Routes.login)}>user.login()</h4>
                <h4 onClick={() => history.push(Routes.register)}>
                  user.register()
                </h4>
              </>
            ) : (
              <h4 onClick={() => history.push("/dashboard/categories")}>
                user.dashboard()
              </h4>
            )}
          </div>
        </div>
        <div
          className="m_event_homepage_container"
          style={{ minHeight: height + 54 }}
        >
          <div className="m_text-center">
            <img
              className="m_login_logo"
              src={config.event_logo || default_event}
              alt=""
              style={{ marginBottom: "4rem" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = default_event;
              }}
            />
          </div>
          <div className="m_table m_m-0">
            <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
              {config.event_name}
            </h3>
            <h5>
              <MDEditor.Markdown source={config.event_about} />
            </h5>
          </div>
          <div className="m_event_footer">
            <div className="text-center">
              <h3>Connect With Us</h3>
            </div>
            <div className="m_contact_us_links">
              <a
                href="mailto:support@redteamacademy.com"
                rel="noreferrer"
                target="_blank"
              >
                <MailOutlineOutlinedIcon />
              </a>
              <a href="tel:+919562505666" rel="noreferrer" target="_blank">
                <PhoneIcon />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=+919562505666"
                rel="noreferrer"
                target="_blank"
              >
                <WhatsAppIcon />
              </a>
              {/* <a
                            href="https://discord.gg/Fhzd2aGxPQ"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <DiscordIcon />
                        </a> */}
              <a
                href="https://www.facebook.com/redteamacademy/"
                rel="noreferrer"
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/redteamacademy"
                rel="noreferrer"
                target="_blank"
              >
                <TwitterIcon />
              </a>
              {/* <a
                            href="https://www.youtube.com/channel/UCZ97BDceDGeogmcm9g374JQ"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <YouTubeIcon />
                        </a> */}
              <a
                href="https://www.linkedin.com/company/redteamacademy/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://www.instagram.com/redteamacademy/"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </div>
            <div className="mt-1 center">
              {/* <h5 className="text-color">
              © 2021 NullTrace Pvt. Ltd. | All rights reserved.
            </h5> */}
              <div className="d-flex">
                <Link to="/termsandcondition">
                  <h6>Terms & conditions</h6>
                </Link>
                <Link to="/privacypolicy" className="ml-2">
                  <h6>Privacy Policy</h6>
                </Link>
              </div>
            </div>
            <div className="mt-1 center">
              {/* <h5 className="text-color">
              © 2021 NullTrace Pvt. Ltd. | All rights reserved.
            </h5> */}
              <div className="d-flex">
                <div className="d-flex">
                  <Link to="/refund-and-cancellation-policy">
                    <h6>Refund and cancellation policy</h6>
                  </Link>
                  <Link to="/contact-us" className="ml-2">
                    <h6>Contact us</h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventPage;
