import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import TableHeading from "./TableHeading";
import TableHeader from "./TableHeader";
import { useSelector } from "react-redux";
import { searchData, paginate } from "./../utils/";
import AddIcon from "@material-ui/icons/Add";
import {
  Close,
  FilterListTwoTone,
  ImportExportOutlined,
} from "@material-ui/icons";
import { api } from "./../../app/services";
function PaginationComponent({
  TableBody,
  items,
  pageSize,
  headers,
  heading,
  handleToggle,
  icon,
  handleFilter,
  filterValue,
  removeFilter,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const search = useSelector((state) => state.search);
  function handlePageChange(page, pages, action = "default") {
    const newPage = currentPage + page - (action === "jump" && currentPage);
    if (newPage > 0 && newPage <= pages) setCurrentPage(newPage);
  }

  var searchItem = items;
  if (search && search.value) {
    searchItem = searchData(search.value, items);
  }
  const totalCount = searchItem?.length;
  let paginateItems = paginate(searchItem, currentPage, pageSize);

  useEffect(() => {
    if (search && search.value) {
      setCurrentPage(1);
    }
  }, [search]);

  async function handleDownloadUsers() {
    try {
      let res;
      if (filterValue) {
        res = await api.exportLogsByBranch(filterValue);
      } else {
        res = await api.exportLogs("users");
      }
      var data = new Blob([JSON.stringify(res.data)], {
        type: "application/json",
      });
      var url = window.URL.createObjectURL(data);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `users${filterValue}.json`);
      tempLink.click();
    } catch (error) {}
  }
  return (
    <>
      <div className="table">
        {heading === "USERS" && (
          <div
            onClick={handleDownloadUsers}
            className="icon icon__primary corner"
          >
            <ImportExportOutlined />
          </div>
        )}
        {handleToggle && (
          <div onClick={handleToggle} className="close">
            <div className="icon__primary">
              <AddIcon />
            </div>
          </div>
        )}
        {handleFilter && (
          <div onClick={handleFilter} className="close corner2">
            <div className="icon__primary">
              <FilterListTwoTone />
            </div>
          </div>
        )}

        {filterValue && (
          <div onClick={removeFilter} className="close corner3 remove-filter">
            {filterValue}
            <Close style={{ fontSize: 16, marginLeft: 5 }} />
          </div>
        )}

        <TableHeading
          handleToggle={handleToggle}
          heading={heading}
          icon={icon}
        />
        <TableHeader headers={headers} />
        <TableBody paginateItems={paginateItems} />
      </div>
      {!searchItem?.length && (
        <div className="center">
          <h3>{`NO ${heading} FOUND`}</h3>
        </div>
      )}
      <Pagination
        itemsCount={totalCount}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </>
  );
}

export default PaginationComponent;
