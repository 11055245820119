import { useEffect, useState } from "react";
import Graph from "./Graph";
import { useSelector, useDispatch } from "react-redux";
import {
  GET_RANKS,
  SEARCH,
  api,
  headers,
  PaginationComponent,
  SmLoading,
  Select,
} from "../../";

import { useHistory } from "react-router";
import branches from "../../../data/branches";

import { Close } from "@material-ui/icons";
const TableBody = ({ paginateItems }) => {
  const history = useHistory();
  const config = useSelector((state) => state.config);
  var endpoint = "/dashboard/profile";
  if (config.team_mode) {
    endpoint = "/dashboard/teamstats";
  }

  return (
    <div className="m_table_data ">
      {paginateItems.map((rank, index) => (
        <div key={index} className="m_table_data_item">
          <div className="m_flex-1">{rank.pos}</div>
          <div
            className="m_flex-4"
            onClick={() => history.push(`${endpoint}/${rank.team}`)}
          >
            {rank.team}
          </div>
          <div className="m_flex-1">{rank.score}</div>
        </div>
      ))}
    </div>
  );
};

function ScoreBoard() {
  const dispatch = useDispatch();
  const [ranks, config] = useSelector((state) => [state.ranks, state.config]);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branch, setBranch] = useState("");
  useEffect(() => {
    async function fetch(loading) {
      if (loading) setLoading(true);
      try {
        let res;
        res = branch
          ? await api.getScoreboardByBranch(branch)
          : await api.getScoreboard();

        if (res.data.standings)
          dispatch({
            type: GET_RANKS,
            data: res.data.standings,
          });
        else {
          dispatch({
            type: GET_RANKS,
            data: [],
          });
        }
        res = branch
          ? await api.graphDataByBranch(branch)
          : await api.graphData();
        if (res.data.graph_data) setGraphData(res.data.graph_data);
      } catch (error) {
      } finally {
        if (loading) setLoading(false);
      }
    }
    fetch(true);
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "scoreboard",
      },
    });
    var timeout = setInterval(async () => {
      fetch(false);
    }, 30000);
    return () => {
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
      clearInterval(timeout);
    };
  }, [dispatch, config.team_mode, branch]);
  if (config.team_mode) headers.ranks[1] = "Team";
  else headers.ranks[1] = "User";

  return (
    <div className="m_scoreboard">
      {loading ? (
        <SmLoading />
      ) : (
        <>
          <Graph graphData={graphData} />

          {branch ? (
            <div className="m_users_branch" onClick={() => setBranch("")}>
              {branch}
              <Close style={{ fontSize: 16, marginLeft: 5 }} />
            </div>
          ) : (
            <Select
              toggle_id={"users_branches"}
              options={branches}
              placeholder="Filter by branch"
              label=""
              onChange={(branch) => {
                setBranch(branch);
              }}
              value={branch}
            />
          )}

          <PaginationComponent
            headers={headers.ranks}
            items={ranks}
            TableBody={(items) => <TableBody paginateItems={items} />}
          />
        </>
      )}
    </div>
  );
}

export default ScoreBoard;
