import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api, Routes, LOADING } from "../../";
import { useHistory } from "react-router";
import WorldMap from "./WorldMap";
import { useSelector } from "react-redux";
function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  let [config] = useSelector((state) => [state.config]);
  const [login, setLogin] = useState(false);
  function highlightCountry(code) {
    const ele = document.querySelector(`#${code}`);
    if (ele) {
      ele.classList.add("animate");
    }
  }
  useEffect(() => {
    async function fetch() {
      try {
        dispatch({
          type: LOADING,
        });
        try {
          await api.getUserStatus();
          setLogin(true);
        } catch (err) {
          setLogin(false);
        }
        var dataText = ["world.hack()"];
        function typeWriter(text, i, fnCallback) {
          if (text && i < text.length) {
            const w = document.getElementById("world_hack");
            if (!w) return;
            w.innerHTML =
              text.substring(0, i + 1) +
              '<span class="typing_cursor" aria-hidden="true"></span>';

            setTimeout(function () {
              typeWriter(text, i + 1, fnCallback);
            }, 200);
          } else if (typeof fnCallback == "function") {
            setTimeout(fnCallback, 700);
          }
        }
        function StartTextAnimation(i) {
          if (dataText[i] && i < dataText[i].length) {
            typeWriter(dataText[i], 0, function () {
              StartTextAnimation(i + 1);
            });
          }
        }
        StartTextAnimation(0);
      } catch (error) {
      } finally {
        dispatch({
          type: LOADING,
          data: false,
        });
      }
    }
    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (config?.countryCode) {
      highlightCountry(config.countryCode);
    }
  }, [config]);

  return (
    <>
      <div className="home">
        <nav>
          <div className="login_register glow">
            {!login ? (
              <>
                <h3 className="glow" onClick={() => history.push(Routes.login)}>
                  user.login()
                </h3>
                <h3
                  className="glow"
                  onClick={() => history.push(Routes.register)}
                >
                  user.register()
                </h3>
              </>
            ) : (
              <h3
                className="glow"
                onClick={() => history.push("/dashboard/challenge")}
              >
                user.dashboard()
              </h3>
            )}
          </div>
        </nav>
        <WorldMap className="country" />
        <WorldMap className="map" />
      </div>
      <div className="home_text">
        <div className="home_text_bottom">
          <div className="line-1 anim-typewriter " id="world_hack">
            world.hack()
          </div>
          <button
            onClick={() => history.push(Routes.event)}
            className="btn--glow"
          >
            Start
          </button>
          <h3 className="glow powered">Powered by RedTeam Hacker Academy</h3>
        </div>
      </div>
    </>
  );
}

export default Home;
