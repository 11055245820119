import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api, Routes, LOADING } from "../../";
import { useHistory } from "react-router";
import WorldMap from "./WorldMap";
import { useSelector } from "react-redux";

function Home({ height }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);
  let [config] = useSelector((state) => [state.config]);
  function highlightCountry(code) {
    const ele = document.querySelector(`#${code}`);
    if (ele) {
      ele.classList.add("m_animate");
    }
  }
  useEffect(() => {
    async function fetch() {
      try {
        dispatch({
          type: LOADING,
        });
        try {
          await api.getUserStatus();
          setLogin(true);
        } catch (err) {
          setLogin(false);
        }

        var dataText = ["world.hack()"];
        function typeWriter(text, i, fnCallback) {
          if (text && i < text.length) {
            const w = document.getElementById("world_hack");
            if (!w) return;
            w.innerHTML =
              text.substring(0, i + 1) +
              '<span class="typing_cursor" aria-hidden="true"></span>';

            setTimeout(function () {
              typeWriter(text, i + 1, fnCallback);
            }, 200);
          } else if (typeof fnCallback == "function") {
            setTimeout(fnCallback, 700);
          }
        }
        function StartTextAnimation(i) {
          if (dataText[i] && i < dataText[i].length) {
            typeWriter(dataText[i], 0, function () {
              StartTextAnimation(i + 1);
            });
          }
        }
        StartTextAnimation(0);
      } catch (error) {
      } finally {
        dispatch({
          type: LOADING,
          data: false,
        });
      }
    }
    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (config?.countryCode) {
      highlightCountry(config.countryCode);
    }
  }, [config]);

  return (
    <div className="m_home_container" style={{ height }}>
      <div className="m_home">
        <nav>
          <div
            className="m_login_register m_glow"
            style={{
              justifyContent: login ? "flex-end" : "space-between",
            }}
          >
            {!login ? (
              <>
                <h4 onClick={() => history.push(Routes.login)}>user.login()</h4>
                <h4 onClick={() => history.push(Routes.register)}>
                  user.register()
                </h4>
              </>
            ) : (
              <h4 onClick={() => history.push("/dashboard/categories")}>
                user.dashboard()
              </h4>
            )}
          </div>
        </nav>
        <WorldMap className="m_country" />
        <WorldMap className="m_map" />
      </div>
      <div className="m_home_text">
        <div className="m_home_text_bottom">
          <div className="m_line-1 m_anim-typewriter" id="world_hack">
            world.hack()
          </div>
          <button
            onClick={() => history.push(Routes.event)}
            className="m_btn--glow"
          >
            Start
          </button>
          <h3 className="m_glow mt-4">Powered by RedTeam Hacker Academy</h3>
        </div>
      </div>
    </div>
  );
}

export default Home;
